.link {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.link:hover {
  color: #0a58ca;
}

.disabled {
  color: #6c757d;
  font-size: 14px;
  cursor: not-allowed;
} 