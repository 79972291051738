/* Main container */
.homePage {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}

/* Hero Section */
.heroSection {
  padding: 120px 0 100px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.heroSection::before {
  content: none;
}

.heroContent {
  position: relative;
  z-index: 2;
}

.heroTextContainer {
  animation: fadeIn 0.8s ease-out;
}

.heroTitle {
  font-size: 3.5rem;
  font-weight: 800;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.highlight {
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heroIcon {
  height: 50px;
  margin-left: 15px;
  animation: float 3s ease-in-out infinite;
}

.heroSubtitle {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--primaryColor2);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.subtitleIcon {
  height: 30px;
  margin-left: 10px;
  animation: float 3s ease-in-out infinite;
  animation-delay: 0.5s;
}

.heroParagraph {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #495057;
  max-width: 700px;
  margin: 0 auto 2rem;
}

.statsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: var(--shadowMd);
  position: relative;
  overflow: hidden;
}

.statsContainer::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, rgba(var(--primaryColor1RGB), 0.03) 0%, rgba(var(--primaryColor2RGB), 0.03) 100%);
  z-index: 0;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

.statNumber {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primaryColor1);
  margin-bottom: 0.5rem;
}

.statLabel {
  font-size: 1rem;
  color: #6c757d;
  font-weight: 500;
}

.statDivider {
  width: 1px;
  height: 50px;
  background: linear-gradient(to bottom, transparent, var(--primaryColor2), transparent);
  opacity: 0.3;
  position: relative;
  z-index: 1;
}

.trustedContainer {
  margin: 0.5rem 0 2.5rem;
  text-align: center;
  padding: 0 1rem;
}

.trustedText {
  font-size: 0.85rem;
  color: var(--textSecondary);
  margin-bottom: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.trustedText::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 40px;
  height: 2px;
  background-color: var(--borderColor);
  transform: translateX(-50%);
}

.universityLogos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 700px;
  margin: 0 auto;
}

.universityLogo {
  opacity: 0.7;
  transition: opacity 0.3s ease;
  filter: grayscale(1);
}

.universityLogo:hover {
  opacity: 1;
  filter: grayscale(0);
}

.universityLogo img {
  height: 40px;
  width: auto;
  object-fit: contain;
}

.heroButtonContainer {
  margin-top: 1rem;
}

.heroButton {
  padding: 0.75rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(var(--primaryColor1RGB), 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  width: auto;
  min-width: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.heroButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(var(--primaryColor1RGB), 0.4);
}

/* Featured Groups Section */
.featuredGroupsSection {
  padding: 100px 0;
  background-color: #fff;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.sectionTitle::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  transform: translateX(-50%);
  border-radius: 2px;
}

.sectionSubtitle {
  font-size: 1.1rem;
  color: #6c757d;
  max-width: 700px;
  margin: 0 auto;
}

/* Benefits Section */
.benefitsSection {
  padding: 100px 0;
  background-color: #f8f9fa;
  position: relative;
}

.benefitsSection::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(var(--primaryColor2RGB), 0.1) 0%, transparent 70%);
  border-radius: 50%;
}

.benefitCard {
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
}

.benefitCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.benefitIcon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.benefitCard h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
}

.benefitCard p {
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;
}

.secondaryCTA {
  margin-top: 2rem;
  padding: 0.75rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  width: auto;
  min-width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Responsive styles */
@media (max-width: 1199px) {
  .heroTitle {
    font-size: 3rem;
  }
  
  .heroIcon {
    height: 45px;
  }
  
  .sectionTitle {
    font-size: 2.2rem;
  }
}

@media (max-width: 991px) {
  .heroSection {
    padding: 100px 0 80px;
  }
  
  .featuredGroupsSection,
  .benefitsSection {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .heroTitle {
    font-size: 2.5rem;
  }
  
  .heroSubtitle {
    font-size: 1.3rem;
  }
  
  .heroIcon {
    height: 40px;
  }
  
  .subtitleIcon {
    height: 25px;
  }
  
  .statsContainer {
    flex-direction: column;
    padding: 1rem;
  }
  
  .statItem {
    padding: 1rem 0;
  }
  
  .statDivider {
    width: 80px;
    height: 1px;
  }
  
  .sectionTitle {
    font-size: 2rem;
  }
  
  .benefitCard {
    margin-bottom: 1rem;
  }
  
  .universityLogos {
    gap: 1rem;
  }
  
  .universityLogo img {
    height: 30px;
  }
  
  .trustedText {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .heroSection {
    padding: 80px 0 60px;
  }
  
  .heroTitle {
    font-size: 2.2rem;
  }
  
  .heroIcon {
    height: 35px;
  }
  
  .heroSubtitle {
    font-size: 1.1rem;
  }
  
  .heroParagraph {
    font-size: 1rem;
  }
  
  .statNumber {
    font-size: 2rem;
  }
  
  .featuredGroupsSection,
  .benefitsSection {
    padding: 60px 0;
  }
  
  .sectionTitle {
    font-size: 1.8rem;
  }
}
