.categoryItem {
  padding: 12px 20px;
  border-radius: 10px;
  background-color: var(--secondaryColor1);
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  min-width: 100px;
  border: 1px solid transparent;
}

.categoryItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.category {
  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
}

.activeCategoryItem {
  background-color: var(--primaryColor1);
  color: white;
  border: 1px solid var(--primaryColor1);
  box-shadow: 0 4px 12px rgba(var(--primaryColor1-rgb), 0.3);
}

.activeCategoryItem .category {
  color: var(--white);
}

@media (max-width: 991px) {
  .categoryItem {
    padding: 10px 16px;
    min-width: 90px;
  }
  .category {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .categoryItem {
    padding: 8px 14px;
    min-width: 80px;
  }
  .category {
    font-size: 13px;
  }
}
