.schoolsPage {
  padding: 2rem 0 4rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  background-image: linear-gradient(to bottom, rgba(240, 244, 248, 0.8) 0%, rgba(249, 249, 249, 1) 100%);
}

.welcomeMessageContainer {
  margin-bottom: 50px;
}

/* Header Styles */
.communityHeader {
  text-align: center;
  margin-bottom: 30px;
  padding: 2rem 1rem;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.communityTitle {
  font-size: 3rem;
  font-weight: 800;
  color: var(--primaryColor1);
  margin-bottom: 1.2rem;
  background: linear-gradient(to right, var(--primaryColor1, #3498db), var(--primaryColor2, #9b59b6));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 0.8s ease-out;
}

.communitySubtitle {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #495057;
  max-width: 700px;
  margin: 0 auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.schoolsContainer {
  max-width: 1200px;
  margin: 0 auto;
}

/* Filter Tabs */
.filterTabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
}

.filterTab {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 30px;
  padding: 0.7rem 1.6rem;
  font-size: 1rem;
  font-weight: 600;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filterTab:hover {
  background-color: #f5f5f5;
  color: #333;
}

.filterTab.active {
  background-color: var(--primaryColor1, #3498db);
  color: white;
  border-color: var(--primaryColor1, #3498db);
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.25);
}

/* Section Headers */
.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1.2rem;
}

.sectionTitle {
  font-size: 1.7rem;
  font-weight: 700;
  color: #333;
  margin: 0;
  position: relative;
}

.sectionTitle:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1.2rem;
  width: 60px;
  height: 3px;
  background: var(--primaryColor1, #3498db);
  border-radius: 3px;
}

/* Schools Grid Container */
.schoolsGridContainer {
  background-color: white;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
  border: 1px solid #eaeaea;
}

.schoolsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.schoolCard {
  background-color: white;
  border-radius: 12px;
  transition: all 0.3s ease;
  height: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  overflow: hidden;
}

.schoolCard:hover {
  transform: translateY(-8px);
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);
  border-color: #e0e0e0;
}

/* No Results */
.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  color: #666;
  text-align: center;
}

.noResults span {
  font-size: 3rem;
  color: #ccc;
  margin-bottom: 1rem;
}

.noResults p {
  font-size: 1.1rem;
}

/* Search Box */
.searchFilterWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.searchBox {
  position: relative;
  flex-grow: 1;
}

.searchBox input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  padding: 0 20px 0 50px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.searchBox input:focus {
  outline: none;
  border-color: var(--primaryColor1, #3498db);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
}

.searchBox span {
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 22px;
}

/* Media Queries */
@media (max-width: 992px) {
  .schoolsGrid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .communityTitle {
    font-size: 2.7rem;
  }
}

@media (max-width: 768px) {
  .communityHeader {
    padding: 1.5rem 1rem;
  }
  
  .communityTitle {
    font-size: 2.3rem;
  }
  
  .sectionHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .searchFilterWrapper {
    width: 100%;
    margin-top: 1rem;
  }
  
  .schoolsGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .schoolsGridContainer {
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .communityTitle {
    font-size: 2rem;
  }
  
  .filterTabs {
    padding: 0.5rem;
    gap: 0.5rem;
  }
  
  .filterTab {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  .sectionTitle {
    font-size: 1.4rem;
  }
  
  .schoolsGrid {
    grid-template-columns: 1fr;
  }
}

/* Small Note Style */
.smallNote {
  font-size: 0.85rem;
  color: #777;
  text-align: center;
  margin-top: 2rem;
  font-style: italic;
} 