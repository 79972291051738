.footerContainer {
  margin-bottom: 40px;
  overflow: hidden;
}
.divider {
  height: 1px;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  margin: 50px 0;
}
.socialIcon {
  margin-right: 25px;
  cursor: pointer;
}
.footerNavSection {
  margin: 0 -100px -24px 0;
}
.footerNavContainer {
  width: auto;
  margin-right: 100px;
}
.footerLinkSectionTitle {
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.footerLink {
  text-decoration: none;
}
.footerLinkText {
  margin-bottom: 24px;
  text-transform: capitalize;
  color: black;
  text-align: center;
}
@media (max-width: 767px) {
  .footerNavContainer {
    margin-bottom: 30px;
  }
  .businessLogoContainer {
    margin-bottom: 50px;
  }
}
