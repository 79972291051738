.expandCollapseIcon {
  margin-right: 16px;
  transition: all 0.3s ease;
  width: 18px;
}
.expand {
  transform: rotate(90deg);
}
.myGroupButtonsContainer {
  margin-bottom: 24px;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myGroupsButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}
.myGroupsButton:hover {
  opacity: 0.85;
}
.pageSubTitle {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}
.createNewGroupButton {
  width: 286px;
  height: 52px;
  margin: 0;
  transition: all 0.2s ease;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.createNewGroupButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}
.myGroupsContainer {
  margin-bottom: 50px;
}
.accordionBody {
  padding: 0 20px 20px;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  margin-top: -25px;
}
.joinGroupTitleContianer {
  margin-bottom: 21px;
}
.emptyGroupsMessage {
  padding: 40px 0;
  text-align: center;
  font-size: 18px;
  color: #6c757d;
  font-style: italic;
}
@media (max-width: 767px) {
  .createNewGroupButton {
    width: 100%;
    margin-bottom: 40px;
  }
  .myGroupButtonsContainer {
    padding: 16px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .accordionBody {
    padding: 0 16px 16px;
  }
}
@media (max-width: 575px) {
  .pageSubTitle {
    font-size: 24px;
  }
  .expandCollapseIcon {
    width: 14px;
  }
}
