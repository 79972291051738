.participantContainer {
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 1.5rem;
  background-color: white;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  height: 100%;
}

.participantContainer:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  transform: translateY(-3px);
}

.participantContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.participantInfo {
  flex: 1;
}

.userName {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primaryColor);
  margin: 0 0 1rem 0;
  text-transform: capitalize;
  line-height: 1.2;
}

.detailsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.detailTag {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #f5f5f5;
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #555;
}

.detailTag span {
  font-size: 18px;
  color: var(--actionColor);
}

.interestsSection {
  margin-bottom: 1.2rem;
}

.interestsTitle {
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0.4rem 0;
  font-weight: 500;
}

.interestsText {
  font-size: 0.95rem;
  color: #333;
  margin: 0;
  line-height: 1.4;
}

.contactSection {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1.2rem;
}

.contactButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f6f9fc;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 0.4rem 0.8rem;
  text-decoration: none;
  color: #555;
  transition: all 0.2s ease;
}

.contactButton:hover {
  background-color: var(--actionColor);
  color: white;
  transform: translateY(-2px);
}

.contactButton:hover .contactIcon {
  filter: brightness(0) invert(1);
}

.contactIcon {
  width: 20px;
  height: 20px;
  transition: all 0.3s;
}

.actionsContainer, .adminActionsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.adminActionsContainer {
  flex-direction: column;
}

.actionButton {
  min-width: 120px;
  height: 40px !important;
  font-size: 0.9rem !important;
}

.removeButton {
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
}

.removeButton:hover {
  background-color: #c0392b !important;
  border-color: #c0392b !important;
}

@media (max-width: 991px) {
  .actionsContainer, .adminActionsContainer {
    margin-top: 1.2rem;
    justify-content: flex-start;
  }
  
  .actionButton {
    width: 100%;
    margin: 0.5rem 0;
  }
}

@media (max-width: 767px) {
  .participantContainer {
    padding: 1.2rem;
  }
  
  .userName {
    font-size: 1.3rem;
  }
  
  .detailsRow {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .contactSection {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .contactButton {
    width: 100%;
    justify-content: center;
  }
}
