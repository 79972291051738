.welcomeMessageContainer {
  margin-bottom: 64px;
}
.groupDetailsContainer {
  margin-bottom: 50px;
}
.tabList {
  margin-bottom: 80px !important;
}
.tabName {
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .tabName {
    font-size: 22px;
  }
}
