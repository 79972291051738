.newsletter {
  font-size: 16px;
  margin-bottom: 150px;
}
.newsletterTitleContainer {
  margin-bottom: 11px;
}
.newsletterTitle {
  font-size: 2em;
  margin: 0;
  font-weight: bold;
  color: var(--primaryColor1);
  text-transform: capitalize;
}
.paperImage {
  width: 35px;
  margin-left: 10px;
  position: relative;
  top: -7px;
}
.newsletterSubTitleContainer {
  margin-bottom: 17px;
}
.newslettterSubTitle {
  font-size: 1.5em;
  margin: 0;
  color: var(--primaryColor2);
  text-transform: capitalize;
  font-weight: 400;
}
.bellImage {
  margin-left: 10px;
}
.newsLetterEmailBoxContainer {
  padding-right: 0;
}
.newsletterEmailBox {
  width: 377px;
  height: 62px;
  border: #e0e0e0 1px solid;
  border-radius: 8px;
  outline: none !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  padding: 16px;
}

.newsletterEmailBox::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--primaryColor2);
  font-size: 20px;
  font-weight: 400;
}
.newsletterEmailBox::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--primaryColor2);
  font-size: 20px;
  font-weight: 400;
}
.newsletterEmailBox:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--primaryColor2);
  font-size: 20px;
  font-weight: 400;
}
.newsletterEmailBox::placeholder {
  /* Modern browsers */
  color: var(--primaryColor2);
  font-size: 20px;
  font-weight: 400;
}
.newsletterActionButtonContainer {
  padding-left: 0;
}
.newsletterActionButton {
  margin: 0;
  width: 161px;
  height: 62px;
  font-size: 20px;
  margin-left: 16px;
}
@media (max-width: 1199px) {
  .newsletterEmailBox {
    height: 55px;
  }
  .newsletterActionButton {
    height: 55px;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .newsletterEmailBox {
    width: 100%;
  }
  .newsletterActionButton {
    width: 130px;
  }
}
@media (max-width: 575px) {
  .newsletterActionButton {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
  .newsLetterEmailBoxContainer {
    padding-right: 12px;
  }
  .newsletterActionButtonContainer {
    padding-left: 12px;
  }
  .newsletterTitle {
    font-size: 1.8em;
  }
  .newslettterSubTitle {
    font-size: 1.2em;
  }
  .paperImage {
    width: 30px;
  }
  .bellImage {
    width: 20px;
  }
  .newsletter {
    margin-bottom: 130px;
  }
}
