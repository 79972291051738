.bundleContainer {
    background: #FFF;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    position: relative;
    border: 1px solid #f0f0f0;
    overflow: hidden;
}

.bundleContainer:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.09);
}

/* Header styling */
.bundleHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f0f0f0;
}

.headerLeft {
    flex: 1;
}

.headerRight {
    padding-left: 15px;
}

.bundleName {
    font-size: 1.4rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 0.5rem;
    line-height: 1.3;
}

/* Special Tags styling */
.specialTagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.specialTag {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.3rem 0.6rem;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.specialTag .material-symbols-outlined {
    font-size: 16px;
}

.specialTag.freeTag {
    background-color: rgba(46, 204, 113, 0.15);
    color: #2ecc71;
    border: 1px solid rgba(46, 204, 113, 0.3);
}

.specialTag.discountTag {
    background-color: rgba(230, 126, 34, 0.15);
    color: #e67e22;
    border: 1px solid rgba(230, 126, 34, 0.3);
}

.bundleImage {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 12px;
    border: 2px solid #f0f0f0;
}

/* Stats section */
.statsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.statItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.75rem;
    border-radius: 8px;
    background-color: #f8f9fa;
    width: 32%;
}

.statIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: linear-gradient(135deg, #3498db, #9b59b6);
    border-radius: 50%;
    margin-bottom: 0.5rem;
}

.statIcon span {
    color: white;
    font-size: 20px;
}

.statInfo {
    display: flex;
    flex-direction: column;
}

.statLabel {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 0.2rem;
}

.statValue {
    font-weight: 700;
    font-size: 1rem;
    color: #333;
}

/* Key info section */
.keyInfoSection {
    margin-bottom: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 1rem;
}

.infoRow {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.infoRow:last-child {
    border-bottom: none;
}

.infoLabel {
    color: #555;
    font-weight: 600;
    font-size: 0.9rem;
}

.infoValue {
    color: #333;
    font-weight: 700;
    font-size: 0.9rem;
}

/* Tags styling */
.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.tag {
    background-color: rgba(52, 152, 219, 0.1);
    color: #3498db;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.discountTag {
    background-color: rgba(230, 126, 34, 0.1);
    color: #e67e22;
}

.freeTag {
    background-color: rgba(46, 204, 113, 0.1);
    color: #2ecc71;
}

/* Footer */
.bundleFooter {
    margin-top: auto;
    padding-top: 1rem;
}

.actionButtonsContainer {
    display: flex;
    justify-content: center;
}

.bundleButton {
    width: 100%;
    max-width: 300px;
}

@media (max-width: 768px) {
    .statsContainer {
        flex-direction: column;
        gap: 0.75rem;
    }
    
    .statItem {
        width: 100%;
    }
}