/* Main container */
.resourcesPage {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  background-color: #f8f9fa;
}

/* Hero Section */
.heroSection {
  padding: 100px 0 60px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
  background: linear-gradient(120deg, #f8f9fa 0%, #e9ecef 100%);
}

.heroContent {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.pageTitle {
  font-size: 3rem;
  font-weight: 800;
  color: var(--primaryColor1);
  margin-bottom: 1.5rem;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 0.8s ease-out;
}

.pageDescription {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #495057;
  max-width: 600px;
  margin: 0 auto;
  animation: fadeIn 0.8s ease-out;
  animation-delay: 0.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

/* Resources Section */
.resourcesSection {
  padding: 60px 0 100px;
  position: relative;
}

.resourceCard {
  height: 100%;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.resourceCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.cardContent {
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
  flex-grow: 1;
}

.iconContainer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, rgba(var(--primaryColor1RGB), 0.1), rgba(var(--primaryColor2RGB), 0.1));
  box-shadow: 0 10px 20px rgba(var(--primaryColor1RGB), 0.1);
  position: relative;
  overflow: hidden;
}

.cardIcon {
  width: 40px;
  height: auto;
  position: relative;
  z-index: 1;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.cardTitle {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
  position: relative;
}

.cardTitle::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 40px;
  height: 3px;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  transform: translateX(-50%);
  border-radius: 2px;
}

.cardDescription {
  font-size: 1rem;
  line-height: 1.6;
  color: #6c757d;
  margin-bottom: 2rem;
  flex-grow: 1;
}

.cardAction {
  margin-top: auto;
}

.actionButton {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(var(--primaryColor1RGB), 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  min-width: 160px;
}

.actionButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(var(--primaryColor1RGB), 0.3);
}

.disabledButton {
  opacity: 0.7;
  cursor: not-allowed;
}

.disabledButton:hover {
  transform: none;
  box-shadow: 0 4px 15px rgba(var(--primaryColor1RGB), 0.2);
}

.cardShapeDivider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 1;
}

.cardShapeDivider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.cardShapeDivider path {
  fill: rgba(255, 255, 255, 0.1);
}

/* Card gradient variants */
.community {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
}

.community .cardTitle,
.community .cardDescription {
  color: #fff;
}

.community .iconContainer {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.community .cardTitle::after {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.5));
}

.college {
  background: linear-gradient(135deg, #13547a 0%, #80d0c7 100%);
}

.college .cardTitle,
.college .cardDescription {
  color: #fff;
}

.college .iconContainer {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.college .cardTitle::after {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.5));
}

.mentor {
  background: linear-gradient(135deg, #f83600 0%, #f9d423 100%);
}

.mentor .cardTitle,
.mentor .cardDescription {
  color: #fff;
}

.mentor .iconContainer {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.mentor .cardTitle::after {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.5));
}

.schools {
  background: linear-gradient(135deg, #4e54c8 0%, #8f94fb 100%);
}

.schools .cardTitle,
.schools .cardDescription {
  color: #fff;
}

.schools .iconContainer {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.schools .cardTitle::after {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.5));
}

/* CTA Section */
.ctaSection {
  padding: 80px 0;
  background: linear-gradient(135deg, var(--primaryColor1) 0%, var(--primaryColor2) 100%);
  text-align: center;
}

.ctaContent {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
}

.ctaTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1.5rem;
}

.ctaDescription {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
}

.ctaButton {
  background-color: #fff;
  color: var(--primaryColor1) !important;
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  min-width: 180px;
  border: none;
}

.ctaButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  background-color: #fff !important;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 1199px) {
  .pageTitle {
    font-size: 2.75rem;
  }
  
  .ctaTitle {
    font-size: 2.25rem;
  }
}

@media (max-width: 991px) {
  .resourcesSection {
    padding: 40px 0 80px;
  }
  
  .heroSection {
    padding: 80px 0 50px;
  }
  
  .ctaSection {
    padding: 60px 0;
  }
}

@media (max-width: 767px) {
  .pageTitle {
    font-size: 2.25rem;
  }
  
  .pageDescription {
    font-size: 1.1rem;
  }
  
  .cardTitle {
    font-size: 1.5rem;
  }
  
  .iconContainer {
    width: 70px;
    height: 70px;
  }
  
  .cardIcon {
    width: 35px;
  }
  
  .ctaTitle {
    font-size: 2rem;
  }
  
  .ctaDescription {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .heroSection {
    padding: 60px 0 40px;
  }
  
  .pageTitle {
    font-size: 2rem;
  }
  
  .resourcesSection {
    padding: 30px 0 60px;
  }
  
  .cardContent {
    padding: 2rem 1.5rem;
  }
  
  .iconContainer {
    width: 60px;
    height: 60px;
    margin-bottom: 1.25rem;
  }
  
  .cardIcon {
    width: 30px;
  }
  
  .cardTitle {
    font-size: 1.35rem;
  }
  
  .cardDescription {
    font-size: 0.9rem;
  }
  
  .actionButton {
    min-width: 140px;
    padding: 0.7rem 1.25rem;
    font-size: 0.9rem;
  }
  
  .ctaTitle {
    font-size: 1.75rem;
  }
  
  .ctaDescription {
    font-size: 0.9rem;
  }
  
  .ctaButton {
    min-width: 160px;
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
  }
} 