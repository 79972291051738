h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Inter", sans-serif;
}

body {
  --primaryColor1: #000000;
  --primaryColor1RGB: 0, 0, 0;
  --primaryColor2: #828282;
  --primaryColor2RGB: 130, 130, 130;
  --secondaryColor1: #f5f5f5;
  --secondaryColor1RGB: 245, 245, 245;
  --actionColor: #000000;
  --actionColorRGB: 0, 0, 0;
  
  /* Add more modern text colors */
  --textPrimary: #333333;
  --textSecondary: #6c757d;
  --textMuted: #8f9397;
  
  /* Add more UI colors */
  --backgroundLight: #f8f9fa;
  --backgroundMedium: #e9ecef;
  --borderColor: #dee2e6;
  
  /* Add shadow values */
  --shadowSm: 0 2px 5px rgba(0, 0, 0, 0.05);
  --shadowMd: 0 4px 15px rgba(0, 0, 0, 0.08);
  --shadowLg: 0 10px 25px rgba(0, 0, 0, 0.1);
}
