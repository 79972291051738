.chatContainer {
  margin-bottom: 100px;
  height: 200px;
}

.messagesContainer{
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.newMessage {
  width: 100%;
  padding: 13px 22px;
  border-radius: 45px;
  margin-bottom: 5px;
  background-color: #f3f3f3;
  border: 1px solid #c7c7c7;
  outline: none !important;
}
.divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #c7c7c7;
  margin: 17px 0 10px;
}
.sendIconContainer {
  width: 50px;
  height: 50px;
  background-color: #285574;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sendIcon {
  color: white;
}
