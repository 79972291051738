.customModalBody {
  padding: 0;
}
.modalCloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
  padding-right: 19px;
  margin-bottom: 12px;
}
.modalCloseButton {
  outline: none !important;
  box-shadow: none !important;
  color: var(--lightGray);
  font-weight: lighter;
  cursor: pointer;
}
@media screen and (min-width: 600px) {
  .modalBodyContainer {
    width: 295px;
  }
}
.modalBodyContainer {
  width: 80%;
  max-width: 365px;
  margin: 0 auto;
}
.title {
  font-weight: 500;
  font-size: 27px;
  line-height: 106.9%;
  text-align: center;
  color: #000000;
  margin-bottom: 37px;
}
.submitBtn,
.backButton {
  margin-top: 48px;
  width: 100%;
  height: 53px;
  background: var(--primaryColor1);
  border-radius: 60px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  margin-bottom: 60px;
}
.backButton {
  background: white;
  border: 1px solid var(--primaryColor1);
  color: black;
}
.submitBtnText {
  font-weight: 500;
  font-size: 16px;
  line-height: 148.9%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: white;
  margin: 0;
}

.componentDiv {
  margin-bottom: 15px;
}
.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 148.9%;
  color: var(--lightGray);
  margin-bottom: 5px;
}

.errorMessage {
  color: red;
  font-size: 13px;
  margin-left: 10px;
}
