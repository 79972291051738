.recievedMessageContainer,
.sentMessageContainer {
  padding: 11px 22px;
  border-radius: 15px;
  margin-bottom: 5px;
  max-width: 500px;
  word-wrap: break-word;
}
.recievedMessageContainer {
  background-color: #f3f3f3;
  
}
.sentMessageContainer {
  background-color: #bee2ed;
}
.recievedMessageText,
.sentMessageText {
  margin: 0;
}
.senderName {
  color: black;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 7px;
}
@media (max-width: 991px) {
  .recievedMessageContainer,
  .sentMessageContainer {
    max-width: 400px;
  }
}
@media (max-width: 767px) {
  .recievedMessageContainer,
  .sentMessageContainer {
    max-width: 80%;
  }
}
