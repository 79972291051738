.customModalBody {
  padding: 0;
}
.modalCloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
  padding-right: 19px;
  margin-bottom: 12px;
}
.modalCloseButton {
  outline: none !important;
  box-shadow: none !important;
  color: var(--lightGray);
  font-weight: lighter;
  cursor: pointer;
}
@media screen and (min-width: 600px) {
  .modalBodyContainer {
    width: 295px;
  }
}
.modalBodyContainer {
  width: 80%;
  max-width: 365px;
  margin: 0 auto;
}
.title {
  font-weight: 500;
  font-size: 27px;
  line-height: 106.9%;
  text-align: center;
  color: #000000;
  margin-bottom: 37px;
}
.orLoginText {
  font-weight: 400;
  font-size: 14px;
  line-height: 148.9%;
  text-align: center;
  color: var(--lightGray);
  margin-bottom: 32px;
}
.submitBtn,
.backButton {
  margin-top: 0px;
  width: 100%;
  height: 53px;
  background: var(--primaryColor1);
  border-radius: 60px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  margin-bottom: 20px;
}
.firstStepSubmitBtn {
  margin-top: 48px;
}
.backButton {
  background: white;
  border: 1px solid var(--primaryColor1);
  color: black;
}
.submitBtnText {
  font-weight: 500;
  font-size: 16px;
  line-height: 148.9%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: white;
  margin: 0;
}
@media screen and (min-width: 600px) {
  .bottomTextDiv {
    display: flex;
    justify-content: center;
  }
}
.bottomTextDiv {
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}
.bottomText {
  font-weight: 400;
  font-size: 13px;
  line-height: 148.9%;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--lightGray);
  margin: 0;
}
.bottomTextLink {
  font-weight: 500;
  font-size: 13px;
  line-height: 148.9%;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--primaryColor2);
  margin: 0 0 0 13px;
  cursor: pointer;
}
.agreeTermsAndconditionsContainer {
  display: flex;
  margin-top: 40px;
  margin-bottom: 25px;
}
.termsAndConditionsCheckbox {
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  top: -2px;
}
.loginText,
.termsAndConditionsText {
  font-weight: 400;
  font-size: 14px;
  line-height: 148.9%;
  text-align: center;
  color: var(--lightGray);
  margin-bottom: 0;
}
.loginText {
  margin-bottom: 42px;
}
.termsAndConditionsText {
  text-align: start;
}
.termsAndConditionsLink {
  color: var(--primaryColor2);
  cursor: pointer;
  font-weight: 500;
}
.loginButton {
  color: var(--primaryColor2);
  cursor: pointer;
  font-weight: 500;
}
.forgotPasswordButton {
  margin-bottom: 50px;
}
