.navbarContainer {
  padding: 15px 0;
}
.customNavItem {
  display: flex;
  padding: 0 18px;
  align-items: center;
}
.navbarContainer .signupButton,
.navbarContainer .loginButton {
  width: 108px;
  height: 52px;
}
.menuIcon {
  font-size: 40px;
}
@media (max-width: 991px) {
  .navbarContainer .signupButton,
  .navbarContainer .loginButton {
    width: 105px;
    /* height: 42px; */
  }
}
