.partnerCard {
  padding: 0 16px;
  margin-bottom: 2rem;
}

.card {
  height: 100%;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: var(--shadowSm);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadowLg);
}

.logoContainer {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  border-bottom: 1px solid var(--borderColor);
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: var(--shadowSm);
  border: 3px solid #fff;
}

.content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.partnerName {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primaryColor1);
  margin-bottom: 0.5rem;
  text-align: center;
}

.partnerDesc {
  font-size: 0.9rem;
  color: var(--textSecondary);
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.testimonial {
  background-color: #f8f9fa;
  padding: 1.25rem;
  border-radius: 12px;
  position: relative;
  margin-top: auto;
}

.quoteIcon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--primaryColor1);
}

.testimonialText {
  font-size: 0.85rem;
  line-height: 1.6;
  color: var(--textPrimary);
  margin: 0;
  padding-left: 1.75rem;
}

@media (max-width: 991px) {
  .partnerCard {
    margin-bottom: 2rem;
  }
}

@media (max-width: 767px) {
  .logoContainer {
    padding: 1.5rem;
  }
  
  .logo {
    width: 70px;
    height: 70px;
  }
  
  .content {
    padding: 1.25rem;
  }
  
  .testimonial {
    padding: 1rem;
  }
}

@media (max-width: 576px) {
  .logo {
    width: 60px;
    height: 60px;
  }
  
  .partnerName {
    font-size: 1.1rem;
  }
  
  .partnerDesc {
    font-size: 0.8rem;
  }
  
  .testimonialText {
    font-size: 0.8rem;
  }
}
