.welcomeMessageContainer {
  margin-bottom: 50px;
}
.expandCollapseIcon {
  margin-right: 16px;
  transition: all 0.3s ease;
  width: 18px;
}
.expand {
  transform: rotate(90deg);
}
.myGroupButtonsContainer {
  margin-bottom: 24px;
}
.myGroupsButton {
  cursor: pointer;
  transition: all 0.2s ease;
}
.myGroupsButton:hover {
  opacity: 0.85;
}
.pageSubTitle {
  font-size: 26px;
  font-weight: 700;
  margin: 0 17px 0 0;
}
.createNewGroupButton {
  width: 286px;
  height: 52px;
  margin: 0;
  transition: transform 0.2s ease;
}
.createNewGroupButton:hover {
  transform: translateY(-2px);
}
.myGroupsContainer {
  margin-top: 40px;
  margin-bottom: 50px;
}
.joinGroup {
  position: relative;
}
.joinGroupCard {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
}
.joinGroupTitleContianer {
  justify-content: space-between;
  margin-bottom: 16px;
}
.filterIconWrapper {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.filterIconWrapper:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.filterIcon {
  width: 22px;
  height: 22px;
}
.groupCarouselContainer {
  margin-bottom: 60px;
}
.categoriesWrapper {
  padding: 10px 0;
}
.categoriesContainer {
  margin-bottom: 10px;
}
.emptyGroupsMessage {
  margin-bottom: 30px;
  font-size: 18px;
  color: #6c757d;
  font-style: italic;
}

.communityPage {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #f9f9f9;
}

.communityHeader {
  text-align: center;
  margin-bottom: 30px;
}

.communityTitle {
  font-size: 3rem;
  font-weight: 800;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 0.8s ease-out;
}

.communitySubtitle {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #495057;
  max-width: 700px;
  margin: 0 auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.verificationMessage {
  margin-top: 15px;
  border-radius: 8px;
  overflow: hidden;
}
.verificationInner {
  padding: 12px 20px;
  background-color: #e7f5ff;
  border-left: 4px solid #0c5460;
}
.verificationMessage p {
  color: #0c5460;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
.tabList {
  margin-bottom: 60px !important;
}
.tabName {
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
}
.colleaguesSection {
  margin-top: 50px;
}
.colleaguesListContainer {
  margin-top: 50px;
}
.accordionBody {
  transition: all 0.3s ease;
}

@media (max-width: 767px) {
  .createNewGroupButton {
    width: 100%;
    margin-bottom: 40px;
  }
  .accordionBody {
    padding: 10px 5px;
  }
  .myGroupButtonsContainer {
    margin-bottom: 20px;
  }
  .joinGroupCard {
    padding: 16px;
  }
  .communityTitle {
    font-size: 2.5rem;
  }
  .communitySubtitle {
    font-size: 1rem;
  }
}
@media (max-width: 575px) {
  .pageSubTitle {
    font-size: 24px;
  }
  .expandCollapseIcon {
    width: 14px;
  }
  .tabName {
    font-size: 20px;
  }
  .colleaguesListContainer {
    margin-top: 40px;
  }
  .communityTitle {
    font-size: 2rem;
  }
} 