.groupsContainer {
  margin-top: 100px;
}
.pageSubTitle {
  font-size: 33px;
  font-weight: 700;
  margin: 0;
}
.filterIcon {
  width: 30px;
  height: 30px;
}
.searchInputBox {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  outline: none !important;
}
.groupListContainer {
  margin-top: 70px;
}
@media (max-width: 575px) {
  .pageSubTitle {
    font-size: 30px;
  }
  .groupListContainer {
    margin-top: 50px;
  }
}

/* Filter styles */
.filterContainer {
  position: relative;
  cursor: pointer;
}

.filterIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.filterIcon:hover {
  transform: scale(1.1);
}

.filterDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  z-index: 100;
  display: none;
}

.filterContainer:hover .filterDropdown {
  display: block;
}

.filterOption {
  padding: 10px 12px;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 6px;
  transition: background-color 0.2s ease;
}

.filterOption:last-child {
  margin-bottom: 0;
}

.filterOption:hover {
  background-color: #f5f5f5;
}

.filterOption.active {
  background-color: #e9ecef;
  font-weight: 600;
}

.redFilter {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
}

.redFilter:hover {
  background-color: rgba(220, 53, 69, 0.9);
}

.orangeFilter {
  color: #fff;
  background-color: rgba(255, 153, 0, 0.8); 
}

.orangeFilter:hover {
  background-color: rgba(255, 153, 0, 0.9);
}

.greenFilter {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8); 
}

.greenFilter:hover {
  background-color: rgba(40, 167, 69, 0.9);
}

.redFilterItem {
  position: relative;
}

.redFilterItem::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #dc3545;
  border-radius: 4px;
}

.orangeFilterItem {
  position: relative;
}

.orangeFilterItem::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #ff9900;
  border-radius: 4px;
}

.greenFilterItem {
  position: relative;
}

.greenFilterItem::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #28a745;
  border-radius: 4px;
}

/* Load more button */
.loadMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loadMoreButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #007bff;
  font-weight: 600;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.loadMoreButton:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.loadMoreIcon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.loadMoreButton:hover .loadMoreIcon {
  transform: rotate(90deg) translateX(2px);
}
