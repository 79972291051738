.findColleaguePage {
  padding: 2rem 0;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.welcomeMessageContainer {
  margin-bottom: 50px;
}

/* Community Header Styles */
.communityHeader {
  text-align: center;
  margin-bottom: 30px;
}

.communityTitle {
  font-size: 3rem;
  font-weight: 800;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 0.8s ease-out;
}

.communitySubtitle {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #495057;
  max-width: 700px;
  margin: 0 auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.usersContainer {
  max-width: 1200px;
  margin: 0 auto;
}

/* Page Header */
.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.pageTitle {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primaryColor);
  margin: 0;
  background: linear-gradient(90deg, var(--primaryColor) 0%, #4a90e2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.searchFilterContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.searchInputWrapper {
  position: relative;
  flex-grow: 1;
}

.searchInput {
  width: 100%;
  height: 46px;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  padding: 0 20px 0 45px;
  font-size: 0.95rem;
  color: #333;
  background-color: white;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.searchInput:focus {
  outline: none;
  border-color: var(--actionColor);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 20px;
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  padding: 0.5rem 1.2rem;
  font-weight: 500;
  font-size: 0.95rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 46px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.filterButton:hover {
  background-color: #f5f5f5;
}

.filterButton.active {
  background-color: var(--actionColor);
  color: white;
  border-color: var(--actionColor);
}

.filterIcon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.filterButton.active .filterIcon {
  filter: brightness(0) invert(1);
}

/* Filter Container */
.filterContainer {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid #eaeaea;
}

.filterSection {
  margin-bottom: 1.5rem;
}

.filterSection:last-child {
  margin-bottom: 0;
}

.filterHeading {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.filterButtons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.scopeFilterButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.7rem 1.2rem;
  font-size: 0.95rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.scopeFilterButton:hover {
  background-color: #f5f5f5;
}

.scopeFilterButton.active {
  background-color: var(--actionColor);
  color: white;
  border-color: var(--actionColor);
}

.scopeFilterButton.active span {
  color: white;
}

.filterActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.clearFiltersButton {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clearFiltersButton:hover {
  background-color: #f5f5f5;
  color: #333;
}

/* Section Headers */
.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.resultCount {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

/* Suggested Colleagues Section */
.suggestedColleaguesSection {
  background-color: #f0f7ff;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid #d0e1f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.infoIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3498db;
  color: white;
  margin-left: 8px;
  cursor: help;
  position: relative;
  transition: all 0.2s ease;
  z-index: 2000;
}

.infoIcon span {
  font-size: 16px;
  font-weight: normal;
}

.infoIcon:hover {
  background-color: #2980b9;
  transform: scale(1.1);
}

.tooltip {
  position: absolute;
  top: 130%;
  right: -10px;
  width: 300px;
  padding: 0.8rem 1rem;
  background-color: #2c3e50;
  color: white;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: normal;
  line-height: 1.4;
  z-index: 9999;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  pointer-events: none;
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 12px;
  width: 12px;
  height: 12px;
  background-color: #2c3e50;
  transform: rotate(45deg);
}

.suggestedColleaguesContainer {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.suggestedColleague {
  position: relative;
  flex: 1;
  min-width: 280px;
  max-width: 350px;
  border-radius: 12px;
  background-color: white;
  padding: 1.5rem 1rem 1rem;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  border: 2px solid #3498db;
}

.suggestedColleague:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.matchBadge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3498db;
  color: white;
  padding: 0.3rem 1rem;
  font-weight: 600;
  font-size: 0.85rem;
  border-bottom-left-radius: 8px;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestedParticipant {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

/* User List Section */
.userListSection {
  margin-top: 2rem;
}

.userGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.userCard {
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.userCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-color: #d0d0d0;
}

/* Loading and Empty States */
.loadingState, .emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  text-align: center;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin: 2rem 0;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--actionColor);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.emptyState span {
  font-size: 48px;
  color: #999;
  margin-bottom: 1rem;
}

.emptyState p {
  color: #666;
  margin-bottom: 1.5rem;
}

/* Responsive */
@media (max-width: 768px) {
  .pageHeader, .sectionHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .searchFilterContainer {
    width: 100%;
  }
  
  .searchInput {
    width: 100%;
  }
  
  .filterButton {
    height: 42px;
    padding: 0.5rem 1rem;
  }
  
  .filterContainer {
    padding: 1.2rem;
  }
  
  .userGrid {
    grid-template-columns: 1fr;
  }
  
  .suggestedColleaguesContainer {
    flex-direction: column;
  }
  
  .suggestedColleague {
    max-width: 100%;
  }
  
  .tooltip {
    width: 250px;
    right: -100px;
  }
  
  .tooltip::before {
    right: 110px;
  }
}

@media (max-width: 576px) {
  .pageTitle {
    font-size: 1.75rem;
  }
  
  .searchFilterContainer {
    flex-direction: column;
    align-items: stretch;
  }
  
  .sectionTitle {
    font-size: 1.25rem;
  }
}

/* Filter Toggle Container */
.filterToggleContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
} 