.autocompleteParent {
  margin-bottom: 15px;
}
.autoCompleteInput > div > div {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border-radius: 40px;
  outline: none !important;
  padding-top: 4px !important;
  padding-bottom: 5px !important;
}
.hiddenFormInput {
  margin: 0;
}
