.partnersSection {
  padding: 100px 0 120px;
  background-color: #fff;
  position: relative;
}

.partnersSection::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(var(--primaryColor1RGB), 0.04) 0%, transparent 70%);
  border-radius: 50%;
  z-index: 0;
}

.partnersSection::after {
  content: '';
  position: absolute;
  right: 0;
  top: 10%;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(var(--primaryColor2RGB), 0.04) 0%, transparent 70%);
  border-radius: 50%;
  z-index: 0;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--primaryColor1);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.sectionTitle::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  transform: translateX(-50%);
  border-radius: 2px;
}

.sectionSubtitle {
  font-size: 1.1rem;
  color: var(--textSecondary);
  max-width: 700px;
  margin: 0 auto 3rem;
}

.cardsContainer {
  margin: 0 -16px;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .partnersSection {
    padding: 80px 0 100px;
  }
  
  .sectionTitle {
    font-size: 2.2rem;
  }
  
  .sectionSubtitle {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 767px) {
  .partnersSection {
    padding: 60px 0 80px;
  }
  
  .sectionTitle {
    font-size: 2rem;
  }
  
  .sectionSubtitle {
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .partnersSection {
    padding: 50px 0 70px;
  }
  
  .sectionTitle {
    font-size: 1.8rem;
  }
  
  .sectionSubtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}
