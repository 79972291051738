.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 148.9%;
  color: var(--lightGray);
  margin-bottom: 5px;
}
.errorMessage {
  color: red;
  font-size: 13px;
  margin-left: 10px;
}

.selectContainerParent .selectContainer {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.selectContainerParent .selectField {
  width: 100%;
  border-radius: 60px;
  outline: none !important;
  height: 46px;
}
