.navbarContainer {
  padding: 15px 0;
}
.customNavItem {
  display: flex;
  padding: 0 18px;
  align-items: center;
}
.navLink {
  color: var(--primaryColor1);
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
}
.navLink:hover {
  color: var(--primaryColor2);
}
.navLink::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, var(--primaryColor1), var(--primaryColor2));
  transition: width 0.3s ease;
}
.navLink:hover::after {
  width: 100%;
}
.navbarContainer .signupButton,
.navbarContainer .loginButton {
  width: 108px;
  height: 52px;
}
.menuIcon {
  font-size: 40px;
}
@media (max-width: 991px) {
  .navbarContainer .signupButton,
  .navbarContainer .loginButton {
    width: 105px;
    /* height: 42px; */
  }
}
