.actionButton {
  background-color: var(--actionColor);
  margin: 20px 0;
  min-width: 140px;
  width: auto;
  height: 50px;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  border-color: var(--actionColor);
  color: white;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.actionButton:hover {
  transform: scale(1.05);
  background-color: var(--actionColor);
  color: white;
}
.actionButton:active {
  background-color: black !important;
  color: white !important;
}
.actionButton:disabled {
  background-color: black !important;
  color: white !important;
}
@media (max-width: 480px) {
  .actionButton {
    min-width: 120px;
    padding: 0.5rem 1rem;
    font-size: 14px;
  }
}
