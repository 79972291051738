.formContainer {
  margin: 60px 0;
}
.formTitle {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  margin-bottom: 60px;
}
.inputSpacer {
  margin-bottom: 10px;
}
.submitBtn {
  margin-top: 50px;
  width: 164px !important;
}
@media (max-width: 575px) {
  .inputSpacer {
    margin-bottom: 0;
  }
  .formContainer {
    margin: 40px 0 60px;
  }
}
