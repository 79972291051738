.componentDiv {
  margin-bottom: 15px;
}
.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 148.9%;
  color: var(--lightGray);
  margin-bottom: 5px;
}
input::placeholder {
  font-family: "Baloo Da 2";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 148.9%;
  color: #000000;
}
.inputSelectTextAreaContainer {
  margin-bottom: 15px;
}
.inputSelectTextArea {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border: 1px solid #aeadad;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  border: 1px solid #aeadad;
  border-radius: 40px;
  padding: 10px 23px;
  outline: none !important;
}
.dropDownArrow {
  position: absolute;
  top: 11px;
  right: 12px;
}
.errorMessage {
  color: red;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  margin-left: 5px;
}
