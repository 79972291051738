.groupContainer {
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  padding: 28px;
  background-color: #fff;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.groupContainer:hover {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.09);
  transform: translateY(-3px);
}

.groupName {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  line-height: 1.3;
}

.actionButton {
  width: 285px;
  height: 52px;
  transition: all 0.2s ease;
  border-radius: 10px;
}

.actionButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.lockInButton {
  background-color: #5a67d8;
  border-color: #5a67d8;
}

.lockInButton:hover {
  background-color: #4c51bf;
  border-color: #4c51bf;
}

.adminActionButton {
  margin: 0;
}

.removeButton {
  margin-bottom: 30px;
}

.status {
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0;
  text-transform: capitalize;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  margin-right: 8px;
}

.inReview {
  color: #f59f00;
  background-color: rgba(255, 184, 0, 0.1);
}

.declined {
  color: #e03131;
  background-color: rgba(255, 0, 0, 0.06);
}

.accepted {
  color: #2b8a27;
  background-color: rgba(6, 164, 3, 0.06);
}

.full {
  color: #f59f00;
  background-color: rgba(255, 0, 0, 0.06);
}

.metaData {
  color: var(--primaryColor2);
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.showVisible:hover ~ .visibleInfo {
  display: block;
}

.visibleInfo {
  display: block;
  color: var(--primaryColor2);
  font-size: 12px;
  margin-top: 8px;
}

.actionButtonLink {
  text-decoration: none;
}

@media (max-width: 991px) {
  .actionButton,
  .adminActionButton {
    width: 240px;
    height: 46px;
    font-size: 14px;
    margin-top: 30px;
  }
  
  .groupContainer {
    padding: 24px;
  }
}

@media (max-width: 767px) {
  .actionButton,
  .adminActionButton {
    width: 100%;
  }
  
  .adminActionButton {
    margin: 0;
  }
  
  .actionButtonLink {
    width: 100%;
  }
  
  .removeButton {
    margin: 30px 0 20px;
  }
  
  .groupContainer {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .groupName {
    font-size: 20px;
  }
  
  .metaData {
    font-size: 14px;
  }
}
