.cardContainer {
  padding: 10px 8px;
  width: 430px;
}
.card {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 0px 15px rgba(0, 0, 0, 0.1);
  min-height: 521px;
  padding: 48px;
  font-size: 16px;
  border-radius: 12px;
}
.noticeBar {
  background-color: var(--secondaryColor1);
  height: 56px;
  width: 100%;
  font-size: 1em;
  padding: 0 24px;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  text-transform: capitalize;
}

.statusBar {
  width: 100%;
  font-size: 1em;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  text-transform: capitalize;
  justify-content: space-around;
}

.statusItem {
  background-color: var(--secondaryColor1);
  padding: 12px;
  margin: 10px;
  border-radius: 12px;
  justify-content: center;
}

.iconContainer {
  margin-right: 40px;
}
.noticeTextContainer {
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.noticeText {
  color: var(--primaryColor1);
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
}
.groupTitle {
  font-size: 1em;
  color: var(--primaryColor1);
  margin-bottom: 12px;
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.availableSeats {
  font-size: 2.5em;
  color: var(--primaryColor1);
  margin-bottom: 32px;
  font-weight: 600;
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participantsCount {
  font-size: 0.5em!important;

}

.category,
.subCategory {
  font-size: 1.25em;
  color: var(--primaryColor2);
  margin-bottom: 12px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


.actionButton {
  margin-top: 32px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 0;
}

.starIcon {
  width: 50px;
  margin: -20px;
  float: right;
}

@media (max-width: 1400px) {
  .cardContainer {
    width: 370px;
  }
  .iconContainer {
    margin-right: 20px;
  }
  .availableSeats {
    font-size: 2.1em;
  }
}
@media (max-width: 1200px) {
  .card {
    padding: 40px 30px;
  }
  .cardContainer {
    width: 310px;
  }
  .availableSeats {
    font-size: 1.8em;
  }
}
@media (max-width: 992px) {
  .cardContainer {
    width: 345px;
  }
  .participantsCount,
  .category,
  .subCategory {
    font-size: 1.2em;
  }
}
@media (max-width: 768px) {
  .cardContainer {
    margin: auto;
  }
}
